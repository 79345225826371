.menu-item-container{
    animation: 1s appear forwards;
    padding: 10px;
}

.menu-item{
    font-family: Open Sans, sans-serif;
    font-size : 1rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    animation: 0.5s slideIn forwards;
    background: #f0f0f0 0 0 no-repeat padding-box;
    height: auto;
    opacity: 1;
    color: black;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 2px;
    padding-top: 2px;
    word-break: break-all;
}

.menu-item:hover{
    color: blue;
}