.modal_two {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
  }
  
  .modal_content_two {
    background-color:#e6f0f2;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 32%;
    padding: 20px;
    border-radius: 7px;
    border: 2px solid black;
  }
  
  .close {
    color: Black;
    float: right;
  }
  
  .close:hover {
    color: cyan;
    cursor: pointer;
  }
  .modal_content_two.modal_description_two{
      font-size: 1.6rem ;
      font-style:italic ;
      margin-top:2rem;
      font-weight: bold;
  }