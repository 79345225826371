.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  
  .image-grid img {
    width: 100%;
    height: auto;
  }
  