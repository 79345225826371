.main-contact-container {
    margin: 0 100px;
    display: flex;
    justify-content: space-between;
    gap: 50px;
    margin-bottom: 100px;
  }
  
  .left-contact > h3 {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-transform: uppercase;
    color: #ff9e58;
    margin: 0;
    padding-bottom: 15px;
  }
  
  .left-contact > h1 {
    font-family: "Montserrat";
    font-size: 48px;
    font-weight: 600;
    line-height: 59px;
    text-transform: uppercase;
    color: #1a4e84;
    margin: 0;
  }
  
  .left-contact > p {
    font-family: "Montserrat-light";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .left-contact .contact-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .left-contact .contact-details .call {
    height: 40px;
    width: 40px;
    left: 0px;
    top: 0px;
    border-radius: 5px;
    background-color: #df2e38;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-contact .contact-details .mail {
    height: 40px;
    width: 40px;
    left: 0px;
    top: 0px;
    border-radius: 5px;
    background-color: #e7b10a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .left-contact .contact-details > p {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  