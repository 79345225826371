.container_get-attendnece  {
    padding-top: 50px;
}

.first {
    border-left: 1px solid black;
    border-top: 1px solid black;
}

.attendenece-title{
 padding-top: 20px;
}

.thead_get-attendnece {
    background-color: rgb(20,60,102);
    color: white;
    font-weight: 600;
}



.second {
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-right: 1px solid black;
}

.row_get-attendnece:last-child{
    border-bottom: 1px solid black;
}