.main-footer {
    height: 600px;
    background-color: #000000;
    padding: 0 100px;
    padding-top: 70px;
    display: flex;
    justify-content: space-between;
   
   
  }
  
  .left-footer > p {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
    width: 500px;
  }
  
  .social-links > h3 {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #fff;
    margin-top: 100px;
  }
  
  .social-links .icons {
    display: flex;
    gap: 20px;
  }
  
  .icon > .social-icon {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  
  .right-footer {
    color: #fff;
    display: flex;
    gap: 30px;
  }
  
  .right-list-footer {
    display: flex;
    flex-direction: column;
  }
  
  .right-list-footer > h5 {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    padding-bottom: 14px;
  }
  
  .right-list-footer > ul {
    margin: 0;
    padding-left: 0;
  }
  
  .right-list-footer > ul > a {
    list-style-type: none;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
  }

  .right-list-footer > ul > li {
    list-style-type: none;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
  }
  
  .last-footer {
    margin-top: -281px;
    margin-left: 65%;
   
  }
  .last-footer .for-enquiry{
    position: relative;
    top: -20px;
  }
  .for-enquiry > h4 {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #fff;
  }
  
  .for-enquiry > input {
    height: 40px;
    width: 320px;
    border-radius: 5px;
    border: none;
    padding-left: 8px;
    margin-right: 15px;
  }
  
  .for-enquiry > button {
    height: 40px;
    width: 96px;
    left: 328px;
    top: 0px;
    border-radius: 5px;
    padding: 10px;
    background-color: #df2e38;
    border: none;
    color: #fff;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
  }
  
  .quick-links > h4 {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #fff;
  }
  
  .quick-links ul {
    margin-top: 10px;
    padding-left: 0;
    display: flex;
    gap: 30px;
  }
  
  .quick-links > ul > li {
    display: flex;
    list-style-type: none;
    text-decoration: underline;
    text-transform: uppercase;
    color: #fff;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  
  .copy{
      font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #FFF;
  padding-left: 30px;
  
  }
  