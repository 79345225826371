.padding-10-right{
    padding-right: 10px
}

.padding-15-left{
    padding-left: 15px
}

.time-dropdown {
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 12px;;
}