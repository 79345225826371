html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body button{
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.margin_bottom_point_2_em {
  margin-bottom: 0.2em !important;
}

.margin_top_1_point_5_em{
  margin-top: 1.5em;
}

#fees {
  font-size: 10px;
}

#fees .form-control {
  font-size: 12px;
}

#selectedStudent .form-group {
  margin-bottom: 2rem;
}

.navExpand{
  display: none;
}

.navIcon{
  display: none;
}

#fees .form-group {
  margin-bottom: 0.5rem;
}
.icon-padding {
  padding-left: 10pt;
  padding-right: 10pt;
}
.fee-receipt {
    color: white;
    padding-left: 10px;
    background-color: rgb(16, 97, 182);
    border-radius: 40px;
    padding-right: 10px;
    font-weight: 600;
}
.fee-receipt-box {
  color: rgb(16, 97, 182);
  padding-left: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(16, 97, 182);
  border-radius: 10px;
  padding-right: 10px;
  font-size: 14px !important;
  /* font-weight: 600; */
}
.bellow-fee-receipt {
    color: rgb(16, 97, 182);
    font-size: 8px !important;
    padding-right: 19px;
}
h2 {
  font-weight: 600 !important;
}

@media (min-width: 576px) {
  #studentFeeModal>.modal-dialog {
    max-width: 800px !important;
}
}

.rectangle-box-1 {
  display: inline;
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 3px;
  padding-left: 3px;
  width:100px !important;
  height:20px !important;
  border:1px solid rgb(16, 97, 182);
}

.rectangle-box-2 {
  display: inline;
  padding-right: 9px;
  padding-top: 10px;
  margin-left: 5px;
  padding-bottom: 10px;
  width:100px !important;
  height:20px !important;
  border:1px solid rgb(16, 97, 182);
}

thead tr th:first-child,
 tbody tr td:first-child {
  width: 70px;
 }

 #printInvice {
  margin-top: 1em;
 }

 .print-invoice {
  background-color: rgb(16, 97, 182) !important;
 }
 .page_padding_fee {
  padding-top: 40px;
  padding-bottom: 50px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  
  .modal-dialog{
    top: 100px !important;
    width: 350px !important;
    left: 5px !important;
  }

  .last-heading{
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    position: absolute;
    margin-right: 37px;
    top: 190px;
    left: -169px;
    width: 478px;
  }

  .act-buttons{
    transform: scale(0.7);
    margin-left: -170px;
  }
  .main-heading{
    margin-left: -62px !important;
    width: 232px;
  }

    .first-heading{

    margin-left: -100px;
  }

  .main-header{
    width: 100% !important;
  }

  .stats-container{
    flex-direction: column;
  }

  .school-founder-container .container{
    flex-direction: column !important;
  }

  .react-player{
    width: 319px !important;
    height: 360px !important;
    margin-left: -20px !important;
  }

  .content > p{
    width: 330px !important;
    text-align: center !important;
    margin-left: 25px !important;
  }

  .welcome-container .school-text{
    width: 250px !important;
  }

  .welcome-container .last-text{
    width: 250px !important;
  }

  .stats-container {
    display: flex;
    justify-content: space-between;
    margin: 0 100px;
    width: 300px !important;
    margin-left: 70px !important;
    margin-bottom: 200px;
}
.principleImg{
  margin-right: 25px !important;
  width: 300px !important;
}

.services-list{
  flex-direction: column !important;
  
}
.secondary-heading{
  transform: scale(0.8);
}

.main-heading{
  display: none !important;
}
.branches{
height: 650px !important;
background-color: #fff !important;
}
.branches-list{
  flex-direction: column !important;
  /* margin-right: 20px !important; */
}

.branch-list-item{
  margin:20px !important;
}

.main-gallery-grid img{
  width: 320px !important;
  margin-left: -70px !important;
}

.testnomials-cards{
flex-direction: column !important;
}

.testnomials-cards .card{
      width: 270px !important;
    margin-left: 0px !important;
}
.testnomials-cards .profile{
  margin-top: 35px !important;
}

.left-footer{
    margin-left: -135px !important;
    transform: scale(0.8) !important;
    margin-top: -100px !important;
}

.main-contact-container{
    /* margin: 0 100px; */
    display: flex !important;
    justify-content: space-between;
    grid-gap: 50px !important;
    gap: 50px !important;
    flex-direction: column !important;
    width: 330px !important;
    margin-left: 0px !important;
    margin-top: 800px !important;
    transform: scale(0.85) !important;

}
.mapImg{
  width: 350px !important;
  margin-left: 20px !important;
}
.left-footer > p{
  width: 350px !important;
  margin-left: 30px !important;
}
.last-footer{
  margin-left: -15px !important;
  -webkit-transform: scale(0.8) !important;
  transform: scale(0.8) !important;
  margin-top: -220px !important;
}
.for-enquiry button{
  margin-top: 10px !important;
}

.right-footer{
  margin-left: -366px !important;
  margin-top: 83px !important;
  -webkit-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
}

.social-links{
  position: relative !important;
  top: 157px !important;
  left: 20px !important;
}
.main-footer{
  height: 700px !important;

}

.logo{
 /* margin-left: 0px; */
 padding: 10px !important;
 /* position: static; */
 /* left: 40px! important; */
 padding-left: 60px !important;
 
}  

.nav-list-items{
  display: block !important;
}

.nav-list-container {
  display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    width: 400px !important;
    height: 300px !important;
    margin-top: 381px !important;
    background: #fff !important;
    width: 0px !important;
    margin-left: -200px !important;
    transition: all 0.3s ease-in-out !important;
}
.nav-list-container.active {
  display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    width: 400px !important;
    height: 300px !important;
    margin-top: 381px !important;
    background: #fff !important;
    width: 180px !important;
    margin-left: -10px !important;
    transition: all 0.3s ease-in-out !important;

}
.navExpand{
  display: block !important;
}
.navExpandImg{
  display: inline-block;
  background-image: url('./userLayout/assets/expand.svg') no-repeat center cover;
}
.navIcon{
  display: block !important;
  width:40px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  padding: 10px;
}
.image-grid img {
  width: 95%;
  height: auto;
}

.testinomials-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e8edf3;
  padding: 0 100px;
  padding-bottom: 100px;
  width: 107%;
  overflow: hidden;
}

.main-footer {
  height: 600px;
  background-color: #000000;
  padding: 0 100px;
  padding-top: 70px;
  display: flex;
  width: 107%;
  overflow: hidden;
}

.branches {
  width: 100%;
  height: 550px;
  background-color: #E8EDF3;
  padding-top: -15px;
  margin-bottom: 500px !important;
  padding-top: unset !important;
}

.stats-container{
  margin-bottom: 100px !important;
}

.testinomials-container > h1{
  font-size: 35px !important;
  text-align: center !important;
}

.announcement{
width: 100% !important;
}

.marquee-container{
  width: 107% !important;
}

.welcome-container .school-name{
  text-align: center !important;
}

.content > h1{
text-align: center !important;
}

.modal-backdrop{
  width: 100% !important;
}
}

/* ipad pro */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {

    .school-founder-container{
      margin-left: 0px !important;
    }
    .testnomials-cards .card{
    width: 300px !important;
    }

    .testnomials-cards .profile{
      margin-top: 10px !important;
    }

    .mapImg{
    vertical-align: middle;
    border-style: none;
    width: 530px !important;
    padding-top: 40px !important;
  }
  .for-enquiry > input{
    margin-bottom:10px;
  }

    .nav-list-items li {
      font-family: "Montserrat";
      text-decoration: none;
      font-size: 12px !important;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: #558e6f;
      width: max-content  !important;
      font-weight: 500;
  }

  .quick-links{
    margin-right:20px;
  }
  
}



/* large screen desktop */
@media  screen and (max-width: 1680px) {
   .nav-list-container{
    margin-right:30px !important;
   }
  
}

/* small medium  */
@media only screen and (min-width: 480px) and (max-width: 1023px){
.nav-list-items li{
  width: max-content !important;
}
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* insert styles here */

  .school-founder-container{
    margin-left: 0px !important;
  }
  .testnomials-cards .card{
  width: 270px !important;
  }

  .testnomials-cards .profile{
    margin-top: 10px !important;
  }

  .mapImg{
  vertical-align: middle;
  border-style: none;
  width: 530px !important;
  padding-top: 40px !important;
}
.for-enquiry > input{
  margin-bottom:10px;
}

  .nav-list-items li {
    font-family: "Montserrat";
    text-decoration: none;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #558e6f;
    width: max-content  !important;
    font-weight: 500;
}

.quick-links{
  margin-right:20px;
}

.nav-list-items{
  display: flex !important;
 }

 .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100% !important;
  height: 100% !important;
  background-color: #000;
}
.content > p{
width: 250px;
}
.testinomials-container{
width: 940px;
}
.branches{
  width: 940px !important;
}
.welcome-container{
width: 850px;
}
.stats-container{
  width: 800px;
}
.branch-image{
  width: 200px !important;
}
.school1 {
  width: 195px !important;
}

.main-contact-container{
width: 800px;
}
.services {
  display: flex;
  flex-direction: column;
  margin: 0 100px;
  text-align: center;
  margin-bottom: 50px;
  width: 800px !important;
}
.sliderContainer{
  width: 940px;
}
.gallery{
  width: 920px;
  margin-left: 10px;
}
.nav-list-container {
  margin-right: 65px !important;
}


.main-footer{
width: 946px !important;
padding: 0 0px !important;
}

.school-founder-container {
  margin-left: 0px !important;
  transform: scale(0.85) !important;
}

}
