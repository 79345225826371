.custom_thumbnail{
    width: 300px;
    height: 200px;;
}

.display_none {
    display:none;
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0;
}