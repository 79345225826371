
@font-face {
    font-family: 'Montserrat';
    src: url('./assets/fonts/Montserrat/static/Montserrat-Medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 100px;
    background-color: #fff; 
    -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    z-index: 1;
  }
  
  .logo img {
    width: 80%;
  }
  
  .hamburger-container {
    margin-left: 20px;
    cursor: pointer;
  }
  
  .nav-list-container {
    display: flex;
    align-items: center;
  }
  
  .nav-list-items {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-list-items li {
    margin-left: 20px;
  }
  
  .nav-list-items li:first-child {
    margin-left: 0;
  }
  
  .nav-list-items li {
      font-family: "Montserrat";
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #558e6f;
    font-weight: 500;
  }
  
  .nav-list-items li:hover {
    cursor: pointer;
    color: #000000;
  }
  
  @media only screen and (max-width: 768px) {
    .main-header {
      padding: 10px;
    }
    
    .nav-list-items {
      display: none;
    }
    
    .hamburger-container {
      display: flex;
    }
  }
  .nav-list-items a{
    margin-left:15px;
    cursor: pointer;
  }
.otherLinksParent:hover .otherLinksUl{
  display: block;
}

  .otherLinksUl{
    z-index: 3;
    position: absolute;
    right: 50px;
    margin-top: 10px;
    background-color: #fff;
    opacity: 1;
    width: 209px;
    top: 61px;
    display:none;
    transition: all 0.3s ease-in-out;
  }
  .otherLinksUl.active{
   display: block;
  }

  .otherLinksUl li{
    list-style: none;
    font-size: 14px;
    padding: 7px;
    margin-right: 5px !important;
  }

  .otherLinksUl li:first-child{
    margin-left: 18px;
  }

  .otherLinksUl a{
    font-family: "Montserrat";
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #558e6f;
    font-weight: 500;
    margin-left: 0px !important;
  }