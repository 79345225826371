@font-face {
    font-family: "Montserrat-bold";
    src: url("./assets/fonts/Montserrat/static/Montserrat-Bold.ttf")
      format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Montserrat-light";
    src: url("./assets/fonts/Montserrat/static/Montserrat-Light.ttf")
      format("truetype");
  
    font-style: normal;
  }
  .welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px;
  }
  
  .welcome-container > .about-school {
    font-family: "Montserrat-bold";
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    color: #ff9e58;
    margin: 0;
    padding-bottom: 10px;
  }
  
  .welcome-container .school-name {
    font-family: "Montserrat-bold";
    font-size: 40px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: left;
    color: #1a4e84;
    margin: 0;
  }
  
  .welcome-container .school-text {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 16px;
  }
  
  .welcome-container .last-text {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .welcome-container button {
    border: none;
    background-color: #558e6f;
    padding: 14px 24px;
    border-radius: 8px;
    font-family: "Montserrat-bold";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 100px;
  }
  
  .stats-container {
    display: flex;
    justify-content: space-between;
    margin: 0 100px;
    margin-bottom: 100px;
  }
  
  .stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }
  
  .stats-logo {
    height: 64px;
    width: 64px;
    left: 0px;
    top: 0px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .stats-logo1 {
    background-color: #df2e38;
  }
  
  .stats-logo2 {
    background-color: #2f58cd;
  }
  
  .stats-logo3 {
    background-color: #e7b10a;
  }
  
  .stats-logo4 {
    background-color: #5d3891;
  }
  
  .stats-number > p {
    font-family: "Montserrat";
    font-size: 16px;
  }
  
  .stats-number .number {
    font-family: "Montserrat-bold";
    font-size: 24px;
    font-weight: 600;
  }
  
  .school-founder-container {
    display: flex;
    flex-direction: column;
    margin: -90 100px;
  }
  
  .school-founder-container .container {
    display: flex;
    align-items: center;
    gap: 60px;
    margin-bottom: 70px;
  }
  
  .content > h1 {
    font-family: "Montserrat-bold";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
  }
  
  .content > p {
    font-family: "Montserrat-light";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .content > button {
    background-color: #558e6f;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 8px 24px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Montserrat-bold";
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .container .image {
    cursor: pointer;
  }
  
  .services {
    display: flex;
    flex-direction: column;
    margin: 0 100px;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .main-heading {
    font-family: "Montserrat-bold";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #ff9e58;
    margin: 0;
    padding-bottom: 20px;
  }
  
  .secondary-heading {
    font-family: "Montserrat-bold";
    font-size: 40px;
    font-weight: 600;
    line-height: 59px;
    color: #1a4e84;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 60px;
  }
  
  .services-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
  }
  
  .list-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }
  
  .services-list .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    height: 64px;
    width: 64px;
    left: 144.5px;
    border-radius: 5px;
  }
  
  .logo1 {
    background-color: #e7b10a;
  }
  
  .logo2 {
    background-color: #2f58cd;
  }
  
  .logo3 {
    background-color: #df2e38;
  }
  
  .list-content > h3 {
    font-family: "Montserrat-bold";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .list-content > p {
    font-family: "Montserrat-light";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .main-gallery-grid{
      margin:0 100px;
      margin-bottom: 100px;
    
  }
  
  .branches{
      width: 100%;
      height: 550px;
      background-color: #E8EDF3;
      padding-top: 30px;
      margin-bottom: 50px
  };


  .branch-list-item{
    margin:10px
  }

  .branch-image{
    width: 240px !important;
   }
  .branch-image img{
    width: 100%;
    border-radius:9px;
  }
.school1{
  width: 235px !important;
}
  .contact-us{
      background-color: red;
  }