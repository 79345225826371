body,
html {
  width: 100%;
  height: 100%;
}

.page_line{
  border-top: 1px solid rgba(85, 142, 111);
}

.page_hr {
  max-width: 50px;
  border-width: 3px !important;
  border-color: #F05F40 !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}
.page_padding {
  padding-top: 40px;
  padding-bottom: 50px;
}

.page_bootom_padding {
  padding-bottom: 40px;
}

.page_left_margin {
  margin-left: 40px;
}
.page_margin_5 {
  margin: 5px;
}

.section-heading{
  color: rgba(20, 60, 102);
  font-size: 1.5rem;
}

.section_paragraph{
  font-size: 17px;
  line-height: 30px;
}

.highlighted_color{
  color: #F05F40 !important;
}

.section_background_color{
  background-color: rgb(239, 249, 243);
}

.icon_color{
  color: rgba(85 142 111);
}

.black_color {
  color: black;
}

.bold{
  font-weight: bold;
}

.custom-card-body{
  max-height: 425px;
  overflow-y: scroll;
}

.padding-zero{
  padding: 0 !important;
}

button:focus {
  outline: none !important;
}

.welcome-page{
  text-align: center;
  font-size: xx-large;
  font-weight: 700;
  font-family: sans-serif;
  padding-top: 1% !important;
  background: antiquewhite;
}

.my-form-design1
{
  width:50%;
  padding:2%;
  background:#f1f1f1;
  /* box-shadow:0 10px 10px black; */
  margin:2% auto;
  border-top:20px solid rgb(94, 94, 248);
}
.text-center{
  font-size: 26px;
}

@media (max-width:900px)
{
  .my-form-design1
  {
    width:90%;
    border:5px solid rgb(104, 104, 241);

  }
  /* .my-form-design1 .form-group ,  */
  .my-form-design1 .form-group label 
  {
    display: flex;
    /* width:90%; */
     /* justify-content: space-between */
    /* align-items: center; */
    /* -ms-grid-column-span: 2; */
  }
  .my-form-design1 .form-group input[type="checkbox"]
  {
   
    width:10%;
  }
}
@media (max-width:1000px)
{
  .my-form-design1
  {
    width:95%;
    

  }
}