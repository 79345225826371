.menu-button-container{
    display:flex;
    flex-direction : column ;
    justify-content: center;
    align-items : center;
    cursor: pointer;
    padding: 4px;
}
.menu-bar-toggler{
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    width: 50px;
    height: 50px;


    color: rgba(0,0,0,.5);
}

.menu-bar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    display: inline-block;
    width: 2.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background-size: 100% 100%;
    margin-left: -13px;
    position: fixed;
}