.announcementContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2px;
    justify-content: center;
    align-items: center;
}

.announcement{
    padding: 5px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    width: 75%;
    border-radius: 9px;
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    cursor: pointer;
}
.announcementIcon{
    margin:2px;
}
.announcementText{
    margin:5px;
}