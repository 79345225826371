@font-face {
    font-family: "Montserrat-bold";
    src: url("./assets/fonts/Montserrat/static/Montserrat-Bold.ttf")
      format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  .slider-image {
    opacity: 1.7;
  }
  .silder-content {
    padding: 0;
    margin: 0;
    color: white;
    position: absolute;
    top: 221px;
    left: 120px;
    font-family: "Montserrat";
  }
  
  .first-heading {
    text-transform: capitalize;
    background-color: #df2e38;
    max-width: 198px;
    height: 41px;
    text-align: center;
    padding: 10px;
    display: grid;
    justify-content: center;

  }
  
  .first-heading > p {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
  }
  
  .main-heading {
    font-family: "Montserrat-bold";
    font-size: 64px;
    font-weight: 600;
    margin: 0;
    padding-top: 10px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .last-heading {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  .act-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .act-buttons a button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #558e6f;
    border: none;
    color: #ffffff;
    padding: 14px;
    border-radius: 8px;
    font-family: "Montserrat-bold";
    font-size: 14px;
    cursor: pointer;
  }
  
  .marquee-container {
    position: relative;
    top: -64px;
    height: 64px;
    display: flex;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    font-family: "Montserrat-bold";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px; 
    color: #FFFFFF;
    text-transform: uppercase;
  }
  