.testinomials-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e8edf3;
    padding: 0 100px;
    padding-bottom: 100px;
   
  }
  .testinomials-container > h3 {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-transform: uppercase;
    color: #ff9e58;
    margin: 0;
    padding-bottom: 15px;
    padding-top: 30px;
  }
  .testinomials-container > h1 {
    font-family: "Montserrat";
    font-size: 48px;
    font-weight: 600;
    line-height: 59px;
    text-transform: uppercase;
    color: #1a4e84;
    margin: 0;
    margin-bottom: 100px;
  }
  
  .testnomials-cards {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }
  
  .testnomials-cards .card {
    height: 209px;
    width: 402.6666564941406px;
    left: 0px;
    top: 0px;
    border-radius: 8px;
    padding: 40px 16px 40px 16px;
    background-color: #ffffff;
  }
  
  .card > p {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .testnomials-cards .profile {
    display: flex;
    align-items: center;
    margin-top: 40px;
    gap: 10px;
  }
  
  .profile > h4{
      font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  
  }
  