.alert {
    background: white;
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: .8rem;
    border: 1px solid rgba(85 142 111);
    margin-bottom: 1rem;
    position: relative;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.04), 0px 12px 25px rgba(0, 0, 0, 0.07);
    -webkit-animation: transitionIn 200ms ease forwards;
    animation: transitionIn 200ms ease forwards;
    transition: all 200ms ease;
}

.alert__icon {
    margin-right: 1rem;
    width: 36px;
    height: 36px;
    -webkit-animation: fade 500ms linear forwards;
    animation: fade 500ms linear forwards;
    color: rgba(85 142 111);
}
#announcment {
    padding-bottom: 100px;
}