.menu-container{
    position: fixed;
    left: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
    background: #000000 0 0 no-repeat padding-box;
    opacity: 0.95;
    color: white;
    transition: height 0.3s ease;
    z-index : 2;
    opacity: 1;
    overflow: hidden;
    top: 80px;
    bottom: 70px;
    max-height: 1500px;
    overflow-y: scroll;
}
.menu-list{
    padding-bottom: 0;
}

.menu-open{
    border : 1px solid black;
}

.menu-close{
    height : 0 ;
}

@media only screen and (max-width: 767px) {
    .menu-container{
        width: 96%;
    }
}

@media only screen and (min-width: 1025px) {
    .menu-container{
        width: 20%;
    }
}