@import url('https://fonts.googleapis.com/css?family=Lobster');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.menuBar-container{
    z-index: 99;
    opacity: 0.9;
    display:flex;
    align-items: center;
    padding-left: 8px;
}

/* .logo{
  margin: 0 auto;
} */

.body{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    filter: blur(2px);
    transition: filter 0.5s ease;
}