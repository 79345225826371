.carousel_section {
    padding-right: 0px;
    padding-left: 0px;
  }

.custom_on_image_btn{
  margin-right: 4px;
  background-color: rgba(85 142 111);;
  border-color: rgba(85 142 111);;
  margin-top: 12px;
}
.custom_on_image_btn:hover{
  background-color:  rgba(85 142 111);
  border-color: rgba(85 142 111);
}

.carousel-caption.d-md-block.carousel-caption_custom{
  background-color: white;
  right: 15%;
  width: 300px;
  left: auto;
  bottom: auto;
  top: 3%;
  border: 1px solid rgba(85 142 111);
  border-radius: 5px;
  padding-top: initial;
}


  