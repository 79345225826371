@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
html{
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body button{
  cursor: pointer;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.margin_bottom_point_2_em {
  margin-bottom: 0.2em !important;
}

.margin_top_1_point_5_em{
  margin-top: 1.5em;
}

#fees {
  font-size: 10px;
}

#fees .form-control {
  font-size: 12px;
}

#selectedStudent .form-group {
  margin-bottom: 2rem;
}

.navExpand{
  display: none;
}

.navIcon{
  display: none;
}

#fees .form-group {
  margin-bottom: 0.5rem;
}
.icon-padding {
  padding-left: 10pt;
  padding-right: 10pt;
}
.fee-receipt {
    color: white;
    padding-left: 10px;
    background-color: rgb(16, 97, 182);
    border-radius: 40px;
    padding-right: 10px;
    font-weight: 600;
}
.fee-receipt-box {
  color: rgb(16, 97, 182);
  padding-left: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(16, 97, 182);
  border-radius: 10px;
  padding-right: 10px;
  font-size: 14px !important;
  /* font-weight: 600; */
}
.bellow-fee-receipt {
    color: rgb(16, 97, 182);
    font-size: 8px !important;
    padding-right: 19px;
}
h2 {
  font-weight: 600 !important;
}

@media (min-width: 576px) {
  #studentFeeModal>.modal-dialog {
    max-width: 800px !important;
}
}

.rectangle-box-1 {
  display: inline;
  margin-right: 5px;
  margin-left: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 3px;
  padding-left: 3px;
  width:100px !important;
  height:20px !important;
  border:1px solid rgb(16, 97, 182);
}

.rectangle-box-2 {
  display: inline;
  padding-right: 9px;
  padding-top: 10px;
  margin-left: 5px;
  padding-bottom: 10px;
  width:100px !important;
  height:20px !important;
  border:1px solid rgb(16, 97, 182);
}

thead tr th:first-child,
 tbody tr td:first-child {
  width: 70px;
 }

 #printInvice {
  margin-top: 1em;
 }

 .print-invoice {
  background-color: rgb(16, 97, 182) !important;
 }
 .page_padding_fee {
  padding-top: 40px;
  padding-bottom: 50px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 320px) and (max-width: 480px) {
  
  .modal-dialog{
    top: 100px !important;
    width: 350px !important;
    left: 5px !important;
  }

  .last-heading{
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    position: absolute;
    margin-right: 37px;
    top: 190px;
    left: -169px;
    width: 478px;
  }

  .act-buttons{
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    margin-left: -170px;
  }
  .main-heading{
    margin-left: -62px !important;
    width: 232px;
  }

    .first-heading{

    margin-left: -100px;
  }

  .main-header{
    width: 100% !important;
  }

  .stats-container{
    flex-direction: column;
  }

  .school-founder-container .container{
    flex-direction: column !important;
  }

  .react-player{
    width: 319px !important;
    height: 360px !important;
    margin-left: -20px !important;
  }

  .content > p{
    width: 330px !important;
    text-align: center !important;
    margin-left: 25px !important;
  }

  .welcome-container .school-text{
    width: 250px !important;
  }

  .welcome-container .last-text{
    width: 250px !important;
  }

  .stats-container {
    display: flex;
    justify-content: space-between;
    margin: 0 100px;
    width: 300px !important;
    margin-left: 70px !important;
    margin-bottom: 200px;
}
.principleImg{
  margin-right: 25px !important;
  width: 300px !important;
}

.services-list{
  flex-direction: column !important;
  
}
.secondary-heading{
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.main-heading{
  display: none !important;
}
.branches{
height: 650px !important;
background-color: #fff !important;
}
.branches-list{
  flex-direction: column !important;
  /* margin-right: 20px !important; */
}

.branch-list-item{
  margin:20px !important;
}

.main-gallery-grid img{
  width: 320px !important;
  margin-left: -70px !important;
}

.testnomials-cards{
flex-direction: column !important;
}

.testnomials-cards .card{
      width: 270px !important;
    margin-left: 0px !important;
}
.testnomials-cards .profile{
  margin-top: 35px !important;
}

.left-footer{
    margin-left: -135px !important;
    -webkit-transform: scale(0.8) !important;
            transform: scale(0.8) !important;
    margin-top: -100px !important;
}

.main-contact-container{
    /* margin: 0 100px; */
    display: flex !important;
    justify-content: space-between;
    grid-gap: 50px !important;
    grid-gap: 50px !important;
    gap: 50px !important;
    flex-direction: column !important;
    width: 330px !important;
    margin-left: 0px !important;
    margin-top: 800px !important;
    -webkit-transform: scale(0.85) !important;
            transform: scale(0.85) !important;

}
.mapImg{
  width: 350px !important;
  margin-left: 20px !important;
}
.left-footer > p{
  width: 350px !important;
  margin-left: 30px !important;
}
.last-footer{
  margin-left: -15px !important;
  -webkit-transform: scale(0.8) !important;
  transform: scale(0.8) !important;
  margin-top: -220px !important;
}
.for-enquiry button{
  margin-top: 10px !important;
}

.right-footer{
  margin-left: -366px !important;
  margin-top: 83px !important;
  -webkit-transform: scale(0.9) !important;
  transform: scale(0.9) !important;
}

.social-links{
  position: relative !important;
  top: 157px !important;
  left: 20px !important;
}
.main-footer{
  height: 700px !important;

}

.logo{
 /* margin-left: 0px; */
 padding: 10px !important;
 /* position: static; */
 /* left: 40px! important; */
 padding-left: 60px !important;
 
}  

.nav-list-items{
  display: block !important;
}

.nav-list-container {
  display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    width: 400px !important;
    height: 300px !important;
    margin-top: 381px !important;
    background: #fff !important;
    width: 0px !important;
    margin-left: -200px !important;
    transition: all 0.3s ease-in-out !important;
}
.nav-list-container.active {
  display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    position: absolute !important;
    width: 400px !important;
    height: 300px !important;
    margin-top: 381px !important;
    background: #fff !important;
    width: 180px !important;
    margin-left: -10px !important;
    transition: all 0.3s ease-in-out !important;

}
.navExpand{
  display: block !important;
}
.navExpandImg{
  display: inline-block;
  background-image: url(/static/media/expand.525c8675.svg) no-repeat center cover;
}
.navIcon{
  display: block !important;
  width:40px;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5px;
  padding: 10px;
}
.image-grid img {
  width: 95%;
  height: auto;
}

.testinomials-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e8edf3;
  padding: 0 100px;
  padding-bottom: 100px;
  width: 107%;
  overflow: hidden;
}

.main-footer {
  height: 600px;
  background-color: #000000;
  padding: 0 100px;
  padding-top: 70px;
  display: flex;
  width: 107%;
  overflow: hidden;
}

.branches {
  width: 100%;
  height: 550px;
  background-color: #E8EDF3;
  padding-top: -15px;
  margin-bottom: 500px !important;
  padding-top: unset !important;
}

.stats-container{
  margin-bottom: 100px !important;
}

.testinomials-container > h1{
  font-size: 35px !important;
  text-align: center !important;
}

.announcement{
width: 100% !important;
}

.marquee-container{
  width: 107% !important;
}

.welcome-container .school-name{
  text-align: center !important;
}

.content > h1{
text-align: center !important;
}

.modal-backdrop{
  width: 100% !important;
}
}

/* ipad pro */
@media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {

    .school-founder-container{
      margin-left: 0px !important;
    }
    .testnomials-cards .card{
    width: 300px !important;
    }

    .testnomials-cards .profile{
      margin-top: 10px !important;
    }

    .mapImg{
    vertical-align: middle;
    border-style: none;
    width: 530px !important;
    padding-top: 40px !important;
  }
  .for-enquiry > input{
    margin-bottom:10px;
  }

    .nav-list-items li {
      font-family: "Montserrat";
      text-decoration: none;
      font-size: 12px !important;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0em;
      text-align: center;
      color: #558e6f;
      width: -webkit-max-content  !important;
      width: max-content  !important;
      font-weight: 500;
  }

  .quick-links{
    margin-right:20px;
  }
  
}



/* large screen desktop */
@media  screen and (max-width: 1680px) {
   .nav-list-container{
    margin-right:30px !important;
   }
  
}

/* small medium  */
@media only screen and (min-width: 480px) and (max-width: 1023px){
.nav-list-items li{
  width: -webkit-max-content !important;
  width: max-content !important;
}
}

/* ipad */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* insert styles here */

  .school-founder-container{
    margin-left: 0px !important;
  }
  .testnomials-cards .card{
  width: 270px !important;
  }

  .testnomials-cards .profile{
    margin-top: 10px !important;
  }

  .mapImg{
  vertical-align: middle;
  border-style: none;
  width: 530px !important;
  padding-top: 40px !important;
}
.for-enquiry > input{
  margin-bottom:10px;
}

  .nav-list-items li {
    font-family: "Montserrat";
    text-decoration: none;
    font-size: 12px !important;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #558e6f;
    width: -webkit-max-content  !important;
    width: max-content  !important;
    font-weight: 500;
}

.quick-links{
  margin-right:20px;
}

.nav-list-items{
  display: flex !important;
 }

 .modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100% !important;
  height: 100% !important;
  background-color: #000;
}
.content > p{
width: 250px;
}
.testinomials-container{
width: 940px;
}
.branches{
  width: 940px !important;
}
.welcome-container{
width: 850px;
}
.stats-container{
  width: 800px;
}
.branch-image{
  width: 200px !important;
}
.school1 {
  width: 195px !important;
}

.main-contact-container{
width: 800px;
}
.services {
  display: flex;
  flex-direction: column;
  margin: 0 100px;
  text-align: center;
  margin-bottom: 50px;
  width: 800px !important;
}
.sliderContainer{
  width: 940px;
}
.gallery{
  width: 920px;
  margin-left: 10px;
}
.nav-list-container {
  margin-right: 65px !important;
}


.main-footer{
width: 946px !important;
padding: 0 0px !important;
}

.school-founder-container {
  margin-left: 0px !important;
  -webkit-transform: scale(0.85) !important;
          transform: scale(0.85) !important;
}

}

body,
html {
  width: 100%;
  height: 100%;
}

.page_line{
  border-top: 1px solid rgba(85, 142, 111);
}

.page_hr {
  max-width: 50px;
  border-width: 3px !important;
  border-color: #F05F40 !important;
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0,0,0,.1);
}
.page_padding {
  padding-top: 40px;
  padding-bottom: 50px;
}

.page_bootom_padding {
  padding-bottom: 40px;
}

.page_left_margin {
  margin-left: 40px;
}
.page_margin_5 {
  margin: 5px;
}

.section-heading{
  color: rgba(20, 60, 102);
  font-size: 1.5rem;
}

.section_paragraph{
  font-size: 17px;
  line-height: 30px;
}

.highlighted_color{
  color: #F05F40 !important;
}

.section_background_color{
  background-color: rgb(239, 249, 243);
}

.icon_color{
  color: rgba(85 142 111);
}

.black_color {
  color: black;
}

.bold{
  font-weight: bold;
}

.custom-card-body{
  max-height: 425px;
  overflow-y: scroll;
}

.padding-zero{
  padding: 0 !important;
}

button:focus {
  outline: none !important;
}

.welcome-page{
  text-align: center;
  font-size: xx-large;
  font-weight: 700;
  font-family: sans-serif;
  padding-top: 1% !important;
  background: antiquewhite;
}

.my-form-design1
{
  width:50%;
  padding:2%;
  background:#f1f1f1;
  /* box-shadow:0 10px 10px black; */
  margin:2% auto;
  border-top:20px solid rgb(94, 94, 248);
}
.text-center{
  font-size: 26px;
}

@media (max-width:900px)
{
  .my-form-design1
  {
    width:90%;
    border:5px solid rgb(104, 104, 241);

  }
  /* .my-form-design1 .form-group ,  */
  .my-form-design1 .form-group label 
  {
    display: flex;
    /* width:90%; */
     /* justify-content: space-between */
    /* align-items: center; */
    /* -ms-grid-column-span: 2; */
  }
  .my-form-design1 .form-group input[type="checkbox"]
  {
   
    width:10%;
  }
}
@media (max-width:1000px)
{
  .my-form-design1
  {
    width:95%;
    

  }
}
.login-container{
    margin-top: 5%;
    margin-bottom: 5%;
}
.login-form-1{
    padding: 5%;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-1 h3{
    text-align: center;
    color: #333;
}
.login-form-2{
    padding: 5%;
    background: #0062cc;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-2 h3{
    text-align: center;
    color: #fff;
}
.login-container form{
    padding: 10%;
}
.btnSubmit
{
    width: 50%;
    border-radius: 1rem;
    padding: 1.5%;
    border: none;
    cursor: pointer;
}
.login-form-1 .btnSubmit{
    font-weight: 600;
    color: #fff;
    background-color: #0C0052;
}
.login-form-2 .btnSubmit{
    font-weight: 600;
    color: #0062cc;
    background-color: #fff;
}
.login-form-2 .ForgetPwd{
    color: #fff;
    font-weight: 600;
    text-decoration: none;
}
.login-form-1 .ForgetPwd{
    color: #0C0052;
    font-weight: 600;
    text-decoration: none;
}

.svg {
  width: 60px;
}
.school_name{
  width: 450px;
  padding-top: 5px;
}

@media only screen and (max-width: 600px) {
  .svg {
    width: 35px;
  }
  .school_name{
    width: 200px;
    padding-top: 1px;
  }
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(20, 60, 102);
    font-weight: 500;
    font-size: small;
    font-family: 'Montserrat', sans-serif;
    /* margin-right: 40px; */
    border-right: 1px solid #d8d6d7;
    text-transform: uppercase;
}
.navbar-light .navbar-nav .nav-link:hover {
  background-color: rgba(20, 60, 102);
  color: white;
}

@media only screen and (max-width: 600px) {
  .navbar-light .navbar-nav .nav-link {
    border-right: 0;
  }
}


.navbar-nav li {
  display:block;
  position:relative;
  white-space: nowrap;
  /* float:left; */
  }
  .navbar-nav li a {
  display:block;
  padding:0;
  text-decoration:none;
  /* width:100px;  */
  /* this is the width of the menu items */
  /* line-height:35px;  */
  /* this is the hieght of the menu items */
  }
  
  .navbar-nav ul {
  position:absolute;
  padding:0;
  left:0;
  display:none; /* hides sublists */
  padding-left:10px;
  z-index: 13;
    background-color: white;
    width: 200px;
    line-height: 30px;
    padding-top: 15px;  
  }
  .navbar-nav li:hover ul {display:block;} /* shows sublist on hover */

  
.svg {
  width: 50px;
}
.school_name{
  width: 400px;
  padding-top: 1px;
  vertical-align: top;
}

@media only screen and (min-width: 401px) and (max-width: 600px) {
  .svg {
    width: 35px;
  }
  .school_name{
    width: 225px;
    padding-top: 3px;
    vertical-align: top;
  }
}

@media only screen and (max-width: 400px) {
  .svg {
    width: 30px;
  }
  .school_name{
    width: 200px;
    padding-top: 3px;
    vertical-align: top;
  }
}

@media only screen and (min-width: 601px) and (max-width: 991px) {
  .svg {
    width: 45px;
  }
  .school_name{
    width: 300px;
    padding-top: 3px;
    vertical-align: top;
  }
}

.btn-link{
  color: black;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(20, 60, 102);
    font-weight: 500;
    font-size: small;
    font-family: 'Montserrat', sans-serif;
    /* margin-right: 40px; */
    border-right: 1px solid #d8d6d7;
    text-transform: uppercase;
    
}
.navbar-light .navbar-nav .nav-link:hover {
  background-color: rgba(20, 60, 102);
  color: white;
}

@media only screen and (max-width: 600px) {
  .navbar-light .navbar-nav .nav-link {
    border-right: 0;
  }
}


.custom-header{
  top: -0.000012099742889404297px;
  left: 0px;
  height: 80px;
  background: #F8F6F8 0% 0% no-repeat padding-box;
  opacity: 1;
}

.custom-brand{
  text-align: right;
}

.admin-navbar.navbar-toggler-icon{
  background-image: url(/static/media/profile.0844b4ab.png) !important;
  height: 1.2em !important;
  width: 1.2em !important;
  background-color: unset;
}

.navbar-toggler{
  border: none !important;
  width: 1.5em !important;
  height: 2em !important;
}

.navbar-brand{
  padding: 0 !important;
}


.admin-navbar.navbar-nav {
  float: right;
}

.admin-navbar-collapse{
  z-index: 1;
}

@media only screen and (max-width: 992px) {
  .admin-navbar {
    background-color:black ;
    width: 158px;
  }
  .admin-navbar li.nav-item .btn-link{
    color: white;
  }
  .admin-navbar li.nav-item a.btn-link{
    padding-top: 0  !important;
  }
}

.admin-navbar li.nav-item a.btn-link{
  padding-top: 6px;;
}

  
.menu-container{
    position: fixed;
    left: 0px;
    width: 30%;
    display: flex;
    flex-direction: column;
    background: #000000 0 0 no-repeat padding-box;
    opacity: 0.95;
    color: white;
    transition: height 0.3s ease;
    z-index : 2;
    opacity: 1;
    overflow: hidden;
    top: 80px;
    bottom: 70px;
    max-height: 1500px;
    overflow-y: scroll;
}
.menu-list{
    padding-bottom: 0;
}

.menu-open{
    border : 1px solid black;
}

.menu-close{
    height : 0 ;
}

@media only screen and (max-width: 767px) {
    .menu-container{
        width: 96%;
    }
}

@media only screen and (min-width: 1025px) {
    .menu-container{
        width: 20%;
    }
}
.menu-item-container{
    -webkit-animation: 1s appear forwards;
            animation: 1s appear forwards;
    padding: 10px;
}

.menu-item{
    font-family: Open Sans, sans-serif;
    font-size : 1rem;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    -webkit-animation: 0.5s slideIn forwards;
            animation: 0.5s slideIn forwards;
    background: #f0f0f0 0 0 no-repeat padding-box;
    height: auto;
    opacity: 1;
    color: black;
    text-align: center;
    vertical-align: middle;
    padding-bottom: 2px;
    padding-top: 2px;
    word-break: break-all;
}

.menu-item:hover{
    color: blue;
}
.menu-button-container{
    display:flex;
    flex-direction : column ;
    justify-content: center;
    align-items : center;
    cursor: pointer;
    padding: 4px;
}
.menu-bar-toggler{
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    width: 50px;
    height: 50px;


    color: rgba(0,0,0,.5);
}

.menu-bar-toggler-icon{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    display: inline-block;
    width: 2.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background-size: 100% 100%;
    margin-left: -13px;
    position: fixed;
}
@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    -webkit-transform: translateX(-2%);
            transform: translateX(-2%);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.menuBar-container{
    z-index: 99;
    opacity: 0.9;
    display:flex;
    align-items: center;
    padding-left: 8px;
}

/* .logo{
  margin: 0 auto;
} */

.body{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    transition: -webkit-filter 0.5s ease;
    transition: filter 0.5s ease;
    transition: filter 0.5s ease, -webkit-filter 0.5s ease;
}
.carousel_section {
    padding-right: 0px;
    padding-left: 0px;
  }

.custom_on_image_btn{
  margin-right: 4px;
  background-color: rgba(85 142 111);;
  border-color: rgba(85 142 111);;
  margin-top: 12px;
}
.custom_on_image_btn:hover{
  background-color:  rgba(85 142 111);
  border-color: rgba(85 142 111);
}

.carousel-caption.d-md-block.carousel-caption_custom{
  background-color: white;
  right: 15%;
  width: 300px;
  left: auto;
  bottom: auto;
  top: 3%;
  border: 1px solid rgba(85 142 111);
  border-radius: 5px;
  padding-top: initial;
}


  
#home{
    padding-top: 10px;
    padding-bottom: 100px;
}
.custom_thumbnail{
    width: 300px;
    height: 200px;;
}

.display_none {
    display:none;
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0;
}
.map-section iframe {
    height: 200px;
    width: 400px;
}
.alert {
    background: white;
    width: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    border-radius: .8rem;
    border: 1px solid rgba(85 142 111);
    margin-bottom: 1rem;
    position: relative;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.04), 0px 12px 25px rgba(0, 0, 0, 0.07);
    -webkit-animation: transitionIn 200ms ease forwards;
    animation: transitionIn 200ms ease forwards;
    transition: all 200ms ease;
}

.alert__icon {
    margin-right: 1rem;
    width: 36px;
    height: 36px;
    -webkit-animation: fade 500ms linear forwards;
    animation: fade 500ms linear forwards;
    color: rgba(85 142 111);
}
#announcment {
    padding-bottom: 100px;
}
.main-footer {
    height: 600px;
    background-color: #000000;
    padding: 0 100px;
    padding-top: 70px;
    display: flex;
    justify-content: space-between;
   
   
  }
  
  .left-footer > p {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
    width: 500px;
  }
  
  .social-links > h3 {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #fff;
    margin-top: 100px;
  }
  
  .social-links .icons {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
  }
  
  .icon > .social-icon {
    height: 40px;
    width: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
  }
  
  .right-footer {
    color: #fff;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
  }
  
  .right-list-footer {
    display: flex;
    flex-direction: column;
  }
  
  .right-list-footer > h5 {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin: 0;
    padding-bottom: 14px;
  }
  
  .right-list-footer > ul {
    margin: 0;
    padding-left: 0;
  }
  
  .right-list-footer > ul > a {
    list-style-type: none;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
  }

  .right-list-footer > ul > li {
    list-style-type: none;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    color: #fff;
  }
  
  .last-footer {
    margin-top: -281px;
    margin-left: 65%;
   
  }
  .last-footer .for-enquiry{
    position: relative;
    top: -20px;
  }
  .for-enquiry > h4 {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #fff;
  }
  
  .for-enquiry > input {
    height: 40px;
    width: 320px;
    border-radius: 5px;
    border: none;
    padding-left: 8px;
    margin-right: 15px;
  }
  
  .for-enquiry > button {
    height: 40px;
    width: 96px;
    left: 328px;
    top: 0px;
    border-radius: 5px;
    padding: 10px;
    background-color: #df2e38;
    border: none;
    color: #fff;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    cursor: pointer;
  }
  
  .quick-links > h4 {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: #fff;
  }
  
  .quick-links ul {
    margin-top: 10px;
    padding-left: 0;
    display: flex;
    grid-gap: 30px;
    gap: 30px;
  }
  
  .quick-links > ul > li {
    display: flex;
    list-style-type: none;
    text-decoration: underline;
    text-transform: uppercase;
    color: #fff;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  
  .copy{
      font-family: "Montserrat";
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #FFF;
  padding-left: 30px;
  
  }
  
.container_get-attendnece  {
    padding-top: 50px;
}

.first {
    border-left: 1px solid black;
    border-top: 1px solid black;
}

.attendenece-title{
 padding-top: 20px;
}

.thead_get-attendnece {
    background-color: rgb(20,60,102);
    color: white;
    font-weight: 600;
}



.second {
    border-left: 1px solid black;
    border-top: 1px solid black;
    border-right: 1px solid black;
}

.row_get-attendnece:last-child{
    border-bottom: 1px solid black;
}
.cursorNotAllowed{
    cursor: not-allowed !important
}
.padding-10-right{
    padding-right: 10px
}

.padding-15-left{
    padding-left: 15px
}

.time-dropdown {
    margin-bottom: 5px;
    margin-left: 5px;
    font-size: 12px;;
}
.modal_two {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 0;
  }
  
  .modal_content_two {
    background-color:#e6f0f2;
    position: absolute;
    top: 20%;
    left: 30%;
    width: 32%;
    padding: 20px;
    border-radius: 7px;
    border: 2px solid black;
  }
  
  .close {
    color: Black;
    float: right;
  }
  
  .close:hover {
    color: cyan;
    cursor: pointer;
  }
  .modal_content_two.modal_description_two{
      font-size: 1.6rem ;
      font-style:italic ;
      margin-top:2rem;
      font-weight: bold;
  }


@font-face {
    font-family: 'Montserrat';
    src: url(/static/media/Montserrat-Medium.b3ba703c.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 100px;
    background-color: #fff;
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    z-index: 1;
  }
  
  .logo img {
    width: 80%;
  }
  
  .hamburger-container {
    margin-left: 20px;
    cursor: pointer;
  }
  
  .nav-list-container {
    display: flex;
    align-items: center;
  }
  
  .nav-list-items {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-list-items li {
    margin-left: 20px;
  }
  
  .nav-list-items li:first-child {
    margin-left: 0;
  }
  
  .nav-list-items li {
      font-family: "Montserrat";
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #558e6f;
    font-weight: 500;
  }
  
  .nav-list-items li:hover {
    cursor: pointer;
    color: #000000;
  }
  
  @media only screen and (max-width: 768px) {
    .main-header {
      padding: 10px;
    }
    
    .nav-list-items {
      display: none;
    }
    
    .hamburger-container {
      display: flex;
    }
  }
  .nav-list-items a{
    margin-left:15px;
    cursor: pointer;
  }
.otherLinksParent:hover .otherLinksUl{
  display: block;
}

  .otherLinksUl{
    z-index: 3;
    position: absolute;
    right: 50px;
    margin-top: 10px;
    background-color: #fff;
    opacity: 1;
    width: 209px;
    top: 61px;
    display:none;
    transition: all 0.3s ease-in-out;
  }
  .otherLinksUl.active{
   display: block;
  }

  .otherLinksUl li{
    list-style: none;
    font-size: 14px;
    padding: 7px;
    margin-right: 5px !important;
  }

  .otherLinksUl li:first-child{
    margin-left: 18px;
  }

  .otherLinksUl a{
    font-family: "Montserrat";
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #558e6f;
    font-weight: 500;
    margin-left: 0px !important;
  }
@font-face {
    font-family: "Montserrat-bold";
    src: url(/static/media/Montserrat-Bold.1f023b34.ttf)
      format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  .slider-image {
    opacity: 1.7;
  }
  .silder-content {
    padding: 0;
    margin: 0;
    color: white;
    position: absolute;
    top: 221px;
    left: 120px;
    font-family: "Montserrat";
  }
  
  .first-heading {
    text-transform: capitalize;
    background-color: #df2e38;
    max-width: 198px;
    height: 41px;
    text-align: center;
    padding: 10px;
    display: grid;
    justify-content: center;

  }
  
  .first-heading > p {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
  }
  
  .main-heading {
    font-family: "Montserrat-bold";
    font-size: 64px;
    font-weight: 600;
    margin: 0;
    padding-top: 10px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .last-heading {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }
  .act-buttons {
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
  }
  .act-buttons a button {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    background-color: #558e6f;
    border: none;
    color: #ffffff;
    padding: 14px;
    border-radius: 8px;
    font-family: "Montserrat-bold";
    font-size: 14px;
    cursor: pointer;
  }
  
  .marquee-container {
    position: relative;
    top: -64px;
    height: 64px;
    display: flex;
    align-items: center;
    text-align: center;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    font-family: "Montserrat-bold";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px; 
    color: #FFFFFF;
    text-transform: uppercase;
  }
  
@font-face {
    font-family: "Montserrat-bold";
    src: url(/static/media/Montserrat-Bold.1f023b34.ttf)
      format("truetype");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Montserrat-light";
    src: url(/static/media/Montserrat-Light.e65ae7ed.ttf)
      format("truetype");
  
    font-style: normal;
  }
  .welcome-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 50px;
  }
  
  .welcome-container > .about-school {
    font-family: "Montserrat-bold";
    font-size: 20px;
    font-weight: 600;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: uppercase;
    color: #ff9e58;
    margin: 0;
    padding-bottom: 10px;
  }
  
  .welcome-container .school-name {
    font-family: "Montserrat-bold";
    font-size: 40px;
    font-weight: 600;
    line-height: 59px;
    letter-spacing: 0em;
    text-align: left;
    color: #1a4e84;
    margin: 0;
  }
  
  .welcome-container .school-text {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 16px;
  }
  
  .welcome-container .last-text {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .welcome-container button {
    border: none;
    background-color: #558e6f;
    padding: 14px 24px;
    border-radius: 8px;
    font-family: "Montserrat-bold";
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    text-transform: uppercase;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 100px;
  }
  
  .stats-container {
    display: flex;
    justify-content: space-between;
    margin: 0 100px;
    margin-bottom: 100px;
  }
  
  .stats {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
  }
  
  .stats-logo {
    height: 64px;
    width: 64px;
    left: 0px;
    top: 0px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .stats-logo1 {
    background-color: #df2e38;
  }
  
  .stats-logo2 {
    background-color: #2f58cd;
  }
  
  .stats-logo3 {
    background-color: #e7b10a;
  }
  
  .stats-logo4 {
    background-color: #5d3891;
  }
  
  .stats-number > p {
    font-family: "Montserrat";
    font-size: 16px;
  }
  
  .stats-number .number {
    font-family: "Montserrat-bold";
    font-size: 24px;
    font-weight: 600;
  }
  
  .school-founder-container {
    display: flex;
    flex-direction: column;
    margin: -90 100px;
  }
  
  .school-founder-container .container {
    display: flex;
    align-items: center;
    grid-gap: 60px;
    gap: 60px;
    margin-bottom: 70px;
  }
  
  .content > h1 {
    font-family: "Montserrat-bold";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: left;
  }
  
  .content > p {
    font-family: "Montserrat-light";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .content > button {
    background-color: #558e6f;
    border: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    padding: 8px 24px;
    color: #ffffff;
    text-transform: uppercase;
    font-family: "Montserrat-bold";
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }
  
  .container .image {
    cursor: pointer;
  }
  
  .services {
    display: flex;
    flex-direction: column;
    margin: 0 100px;
    text-align: center;
    margin-bottom: 50px;
  }
  
  .main-heading {
    font-family: "Montserrat-bold";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-align: center;
    text-transform: uppercase;
    color: #ff9e58;
    margin: 0;
    padding-bottom: 20px;
  }
  
  .secondary-heading {
    font-family: "Montserrat-bold";
    font-size: 40px;
    font-weight: 600;
    line-height: 59px;
    color: #1a4e84;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 60px;
  }
  
  .services-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 60px;
    gap: 60px;
  }
  
  .list-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-gap: 30px;
    gap: 30px;
  }
  
  .services-list .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    height: 64px;
    width: 64px;
    left: 144.5px;
    border-radius: 5px;
  }
  
  .logo1 {
    background-color: #e7b10a;
  }
  
  .logo2 {
    background-color: #2f58cd;
  }
  
  .logo3 {
    background-color: #df2e38;
  }
  
  .list-content > h3 {
    font-family: "Montserrat-bold";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .list-content > p {
    font-family: "Montserrat-light";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
  }
  
  .main-gallery-grid{
      margin:0 100px;
      margin-bottom: 100px;
    
  }
  
  .branches{
      width: 100%;
      height: 550px;
      background-color: #E8EDF3;
      padding-top: 30px;
      margin-bottom: 50px
  };


  .branch-list-item{
    margin:10px
  }

  .branch-image{
    width: 240px !important;
   }
  .branch-image img{
    width: 100%;
    border-radius:9px;
  }
.school1{
  width: 235px !important;
}
  .contact-us{
      background-color: red;
  }
.image-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }
  
  .image-grid img {
    width: 100%;
    height: auto;
  }
  
.announcementContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2px;
    justify-content: center;
    align-items: center;
}

.announcement{
    padding: 5px;
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
    font-size: 15px;
    background-color: #fff;
    display: flex;
    justify-content: flex-start;
    width: 75%;
    border-radius: 9px;
    box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    cursor: pointer;
}
.announcementIcon{
    margin:2px;
}
.announcementText{
    margin:5px;
}
.main-contact-container {
    margin: 0 100px;
    display: flex;
    justify-content: space-between;
    grid-gap: 50px;
    gap: 50px;
    margin-bottom: 100px;
  }
  
  .left-contact > h3 {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-transform: uppercase;
    color: #ff9e58;
    margin: 0;
    padding-bottom: 15px;
  }
  
  .left-contact > h1 {
    font-family: "Montserrat";
    font-size: 48px;
    font-weight: 600;
    line-height: 59px;
    text-transform: uppercase;
    color: #1a4e84;
    margin: 0;
  }
  
  .left-contact > p {
    font-family: "Montserrat-light";
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }
  .left-contact .contact-details {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 10px;
  }
  
  .left-contact .contact-details .call {
    height: 40px;
    width: 40px;
    left: 0px;
    top: 0px;
    border-radius: 5px;
    background-color: #df2e38;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .left-contact .contact-details .mail {
    height: 40px;
    width: 40px;
    left: 0px;
    top: 0px;
    border-radius: 5px;
    background-color: #e7b10a;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .left-contact .contact-details > p {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }
  
.testinomials-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e8edf3;
    padding: 0 100px;
    padding-bottom: 100px;
   
  }
  .testinomials-container > h3 {
    font-family: "Montserrat";
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    text-transform: uppercase;
    color: #ff9e58;
    margin: 0;
    padding-bottom: 15px;
    padding-top: 30px;
  }
  .testinomials-container > h1 {
    font-family: "Montserrat";
    font-size: 48px;
    font-weight: 600;
    line-height: 59px;
    text-transform: uppercase;
    color: #1a4e84;
    margin: 0;
    margin-bottom: 100px;
  }
  
  .testnomials-cards {
    display: flex;
    flex-direction: row;
    grid-gap: 30px;
    gap: 30px;
  }
  
  .testnomials-cards .card {
    height: 209px;
    width: 402.6666564941406px;
    left: 0px;
    top: 0px;
    border-radius: 8px;
    padding: 40px 16px 40px 16px;
    background-color: #ffffff;
  }
  
  .card > p {
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .testnomials-cards .profile {
    display: flex;
    align-items: center;
    margin-top: 40px;
    grid-gap: 10px;
    gap: 10px;
  }
  
  .profile > h4{
      font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  
  }
  
